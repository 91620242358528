import { routesBuilder } from '@guiker/api-definition'

import { NEWPropertyOwnership, WithPropertyHasBuilding } from '../../../entity'
import { upsertRealOwnerSchema } from '../schemas/upsert-real-owner'
import { paths } from './paths'

const basePath = paths.NEWPropertyOwnership.all.withId(':id')
const { buildPut, buildGet } = routesBuilder<NEWPropertyOwnership>()({ basePath })

export const NEWPropertyOwnershipRoutes = {
  updateToRealOwner: buildPut({
    path: '/update-to-real-owner',
    payloadSchema: upsertRealOwnerSchema,
  }),
  readOneNEWPropertyOwnership: buildGet({
    response: null as WithPropertyHasBuilding<NEWPropertyOwnership>,
  }),
}
