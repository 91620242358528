import { CountByStatusMetadata, paginated, routesBuilder } from '@guiker/api-definition'

import { Booking, BookingStatus } from '../../entity'
import { paths } from './paths'
import {
  addUnitManagerPayloadSchema,
  readAllBookingsQueryParamsSchema,
  readAllBookingsWithListingIdsQueryParamsSchema,
  replaceListingUnitPayloadSchema,
  setEarlyTerminationSchema,
  updateBookingInfoSchema,
} from './schema'

const BOOKING_WITH_ID_PATH = paths.booking.one.withId(':bookingId')

const base = routesBuilder<Booking>()({ basePath: paths.booking.one.base() })
const idNested = routesBuilder<Booking>()({ basePath: BOOKING_WITH_ID_PATH })
const token = routesBuilder<string>()({ basePath: `${BOOKING_WITH_ID_PATH}/generate-access-token` })
const baseMultiple = routesBuilder<Booking[]>()({ basePath: paths.booking.one.base() })

export const routes = {
  readOneBooking: idNested.buildGet({}),
  readAllBookings: base.buildGet({
    paginated,
    path: `${paths.booking.all.base()}/search`,
    queryParamsSchema: readAllBookingsQueryParamsSchema,
    additionalMeta: {} as CountByStatusMetadata<BookingStatus>,
  }),
  readAllBookingsWithListingIds: baseMultiple.buildGet({
    path: `${paths.booking.all.base()}/with-listing-ids`,
    queryParamsSchema: readAllBookingsWithListingIdsQueryParamsSchema,
  }),
  voidConfirmedBooking: idNested.buildPut({
    path: '/void',
  }),
  updateBookingInfo: idNested.buildPut({
    path: '/update-info',
    payloadSchema: updateBookingInfoSchema,
  }),
  changeLeaseTerm: idNested.buildPut({
    path: '/change-lease-term',
    payloadSchema: setEarlyTerminationSchema,
  }),
  addUnitManager: idNested.buildPost({
    path: '/invite',
    payloadSchema: addUnitManagerPayloadSchema,
  }),
  replaceListingUnit: idNested.buildPut({
    path: '/replace-listing-unit',
    payloadSchema: replaceListingUnitPayloadSchema,
  }),
  generateAccessControlToken: token.buildGet({}),
}
